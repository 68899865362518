import {useEffect, useRef} from 'react';

export default function useHelper() {
  const unMounted =  (fn: () => void): void => {
    const fnRef = useRef(fn);
    fnRef.current = fn;

    useEffect(() => () => fnRef.current(), []);
  };

  return {
    unMounted,
  }
}

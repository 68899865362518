import Breadcrumb from '@common/auth/ui/ito/breadcrumb';
import {Trans} from '@common/i18n/trans';
import React from 'react';
import {Link} from 'react-router-dom';

export function ITO_PaymentGatewayCreatePage() {
  const breadcrumbItems = [
    {label: 'Payment Gateway', path: '/ito/payments/gateway'},
    {label: 'Add Gateway', path: ''},
  ];

  return (
    <div className="w-full">
      <Breadcrumb items={breadcrumbItems} />

      <header className="sm:mt-7 mb-6 flex flex-col md:flex-row space-y-3 md:space-y-0 items-start justify-between">
        <div className="w-4/5">
          <h2 className="mb-3 text-xl font-semibold text-black">
            <Trans message="Payment Gateway" />
          </h2>
          <p className="text-gray-500 text-sm">
            <Trans message="When you're ready to charge your customers, you'll use a payment gateway for the transaction. Card details are always stored securely in the PCI DSS compliant ReSkillify vault, so you can change gateways at any time without needing to migrate card data." />
          </p>
        </div>
        <button className="bg-purple-800 hover:text-purple-800 hover:border-purple-800 py-1.5 whitespace-nowrap rounded-md border-2 border-transparent px-3 text-sm font-semibold text-white hover:bg-transparent">
          <Trans message="+ Add Gateway" />
        </button>
      </header>

      <section className="border-gray-300 mb-6 flex items-center justify-between rounded-lg border-2 border-dashed p-4">
        <div>
          <h2 className="text-gray-700 mb-2 text-lg font-semibold">
            <Trans message="Configure Payment Gateway" />
          </h2>
          <p className="text-gray-600 mb-4 text-sm">
            <Trans message="The account doesn't have a payment gateway configured yet." />
          </p>
          <div className="flex flex-col items-start lg:flex-row space-x-0 lg:space-x-2 space-y-2 lg:space-y-0">
            <button className="whitespace-nowrap bg-purple-200 text-purple-800 hover:bg-purple-200 flex items-center rounded-lg px-4 py-2 text-sm font-semibold">
              <span className="mr-2">+</span>
              <Trans message="Create New Payment Gateway" />
            </button>
            <Link to='/ito/payments/gateway/choose' className="whitespace-nowrap border-gray-300 text-gray-700 hover:bg-gray-100 flex items-center rounded-lg border px-4 py-2 text-sm font-semibold shadow">
              <span className="mr-2">+</span>
              <Trans message="Add a current Payment Gateway" />
            </Link>
          </div>
        </div>
        <div className="max-w-full overflow-hidden relative hidden xl:flex items-center">
          <img
            src="/images/icons/background_complete.svg"
            alt="Background"
            className="inset-0 -mr-8 h-full w-full object-cover"
          />
          <img
            src="/images/icons/character_female.svg"
            alt="Character"
            className="z-10 -mr-8"
          />
          <img
            src="/images/icons/account.svg"
            alt="Account"
            className="z-20 ml-2"
          />
        </div>
      </section>

      {/* Manual Payment Methods Section */}
      <section className="border-gray-200 mb-6 rounded-lg border bg-white p-4 shadow-sm">
        <h2 className="text-gray-700 mb-2 text-lg font-semibold">
          <Trans message="Manual Payment Methods" />
        </h2>
        <p className="text-gray-600 mb-4 text-sm">
          <Trans
            message="If your payment doesn't come under the available payment gateways, you
          can configure a custom payment through Zapier."
          />
        </p>
        <button className="border-gray-300 text-gray-700 hover:bg-gray-100 rounded-lg border bg-white px-4 py-2 text-sm font-semibold">
          <Trans message="+ Add Manual Payment" />
        </button>
      </section>

      {/* Additional Manual Payment Methods Section */}
      <section className="border-gray-200 rounded-lg border bg-white p-4 shadow-sm">
        <h2 className="text-gray-700 mb-2 text-lg font-semibold">
          <Trans message="Manual Payment Methods" />
        </h2>
        <p className="text-gray-600 mb-4 text-sm">
          <Trans
            message="For manual payments, you'll need to approve orders made outside your
          online store."
          />
        </p>
        <button className="border-gray-300 text-gray-700 hover:bg-gray-100 rounded-lg border bg-white px-4 py-2 text-sm font-semibold">
          <Trans message="+ Add Manual Payment" />
        </button>
      </section>
    </div>
  );
}

export function PencilIcon () {
  return (
    <svg width="19" height="19" viewBox="0 0 19 19" fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.39674 15.0964C1.43503 14.7518 1.45417 14.5795 1.5063 14.4185C1.55255 14.2756 1.6179 14.1396 1.70057 14.0142C1.79375 13.8729 1.91633 13.7504 2.16148 13.5052L13.1667 2.49999C14.0872 1.57951 15.5796 1.57951 16.5001 2.49999C17.4205 3.42046 17.4205 4.91285 16.5001 5.83332L5.49481 16.8386C5.24966 17.0837 5.12709 17.2063 4.98578 17.2995C4.86041 17.3821 4.72445 17.4475 4.58158 17.4937C4.42054 17.5459 4.24826 17.565 3.90368 17.6033L1.08337 17.9167L1.39674 15.0964Z"
        stroke="currentColor" strokeWidth="1.66667" strokeLinecap="round"
        strokeLinejoin="round" />
    </svg>
  );
}

import { FC, ButtonHTMLAttributes } from 'react';
import { Trans } from '@common/i18n/trans';

interface SubmitButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  isDisabled: boolean;
  message: string;
}

const SubmitButton: FC<SubmitButtonProps> = ({
   isDisabled,
   message,
   className = '',
   ...props
 }) => {
  return (
    <button
      disabled={isDisabled}
      className={`mt-4 rounded-lg px-5 py-2 text-sm text-white ${
        isDisabled ? 'bg-gray-400 cursor-not-allowed' : 'bg-purple-800 hover:bg-purple-900'
      } ${className}`}
      {...props}
    >
      <Trans message={message} />
    </button>
  );
};

export default SubmitButton;

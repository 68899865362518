import {User} from '@common/auth/user';
import {AccountSettingsId} from '@common/ui/layout/ito/sidebar/sidebar';
import {Trans} from '@common/i18n/trans';
import GoogleIcon
  from '@common/auth/ui/ito/account-settings/social-login-panel/google-icon';
import FacebookIcon
    from '@common/auth/ui/ito/account-settings/social-login-panel/facebook-icon';
import TwitterIcon
  from '@common/auth/ui/ito/account-settings/social-login-panel/twitter-icon';
import {
  SocialLoginPanelRow
} from '@common/auth/ui/ito/account-settings/social-login-panel/social-login-panel-row';

interface Props {
  user: User;
}

export function ITO_SocialLoginPanel ({user}: Props) {
  return (
    <section
      id={AccountSettingsId.SocialLogin}
      className="border-gray-300 mb-6 rounded-lg border-b bg-white p-6 shadow">
      <h2 className="mb-4 border-b pb-2 text-lg font-semibold">
        <Trans message="Manage Social Login" />
      </h2>
      <div className="flex flex-col gap-4">
        <SocialLoginPanelRow
          service="google"
          icon={<GoogleIcon />}
          user={user}
        />
        <SocialLoginPanelRow
          service="facebook"
          icon={<FacebookIcon />}
          user={user}
        />
        <SocialLoginPanelRow
          service="twitter"
          icon={<TwitterIcon />}
          user={user}
        />
      </div>
      <p className="text-gray-500 mt-4 text-sm">
        <Trans message="If you disable social logins, you'll still be able to log in using
        your email and password." />
      </p>
    </section>
  )
}

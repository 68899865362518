export function UpDownArrowsIcon () {
  return (
    <svg width="16" height="12" viewBox="0 0 16 12" fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8 7.60775L11.5 11L15 7.60775M8 4.30387L4.5 1M4.5 1L1 4.3014M4.5 1V10.9116M11.5 1V10.9116"
        stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

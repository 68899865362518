import {AccountSettingsId} from '@common/ui/layout/ito/sidebar/sidebar';
import {Trans} from '@common/i18n/trans';

export function ITO_ActiveSessionsPanel() {
  return (
    <section
      id={AccountSettingsId.Sessions}
      className="border-gray-300 mb-6 rounded-lg border-b bg-white p-6 shadow">
      <h2 className="mb-4 border-b pb-2 text-lg font-semibold">
        <Trans message='Active Sessions' />
      </h2>
      <p className="text-gray-500 mb-4 text-sm">
        <Trans message='If necessary, you may log out of all of your other browser sessions
        across all of your devices. Your recent sessions are listed below.' />
      </p>
      <button
        className="border-gray-400 text-gray-700 hover:bg-gray-100 rounded-lg border px-6 py-2 text-sm">
        <Trans message='Logout Other Sessions' />
      </button>
    </section>
  )
}

import { FC } from 'react';
import { FieldErrors, UseFormRegister } from 'react-hook-form';
import { Trans } from '@common/i18n/trans';

interface InputFieldProps {
  label: string;
  name: string;
  register: UseFormRegister<any>;
  required?: boolean | string;
  errors: FieldErrors;
  type?: string;
  placeholder?: string;
  autoComplete?: string;
  className?: string;
}

const InputField: FC<InputFieldProps> = ({
   label,
   name,
   register,
   required = false,
   errors,
   type = 'text',
   placeholder = '',
   autoComplete = '',
   className = 'border-gray-300 px-2.5 py-1.5 w-full rounded border',
 }) => {
  return (
    <div className="mb-4">
      <label className="mb-2 block text-sm">
        <Trans message={label} />
      </label>
      <input
        {...register(name, { required })}
        type={type}
        placeholder={placeholder}
        autoComplete={autoComplete}
        className={className}
      />
      {errors?.[name] && (
        <span className="text-xs text-red-500">{errors[name]?.message as string}</span>
      )}
    </div>
  );
};

export default InputField;

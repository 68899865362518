export function StarIcon () {
  return (
    <svg width="10" height="10" viewBox="0 0 10 10" fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.26954 0C5.47892 0 5.67973 0.0831764 5.82778 0.231231C5.97584 0.379286 6.05902 0.580092 6.05902 0.789474V3.63158L8.52112 2.21053C8.61097 2.15869 8.71016 2.12506 8.813 2.11155C8.91585 2.09804 9.02036 2.10493 9.12055 2.13181C9.22074 2.15869 9.31465 2.20504 9.39693 2.26821C9.4792 2.33139 9.54823 2.41015 9.60007 2.5C9.65191 2.58985 9.68554 2.68903 9.69905 2.79188C9.71255 2.89473 9.70567 2.99924 9.67879 3.09943C9.65191 3.19962 9.60556 3.29353 9.54238 3.37581C9.47921 3.45808 9.40045 3.52711 9.31059 3.57895L6.84849 5L9.31165 6.42105C9.49311 6.52574 9.62556 6.69823 9.67984 6.90057C9.73413 7.10292 9.70581 7.31854 9.60112 7.5C9.49643 7.68146 9.32394 7.81391 9.1216 7.8682C8.91926 7.92248 8.70364 7.89416 8.52217 7.78947L6.05902 6.36737V9.21053C6.05902 9.41991 5.97584 9.62071 5.82778 9.76877C5.67973 9.91682 5.47892 10 5.26954 10C5.06016 10 4.85936 9.91682 4.7113 9.76877C4.56325 9.62071 4.48007 9.41991 4.48007 9.21053V6.36737L2.01691 7.78842C1.83628 7.88461 1.62535 7.9069 1.4286 7.85059C1.23185 7.79427 1.06466 7.66376 0.962269 7.48656C0.859883 7.30937 0.830306 7.09934 0.879775 6.90076C0.929243 6.70218 1.05389 6.53056 1.22744 6.42211L3.6906 5L1.22849 3.57895C1.04703 3.47426 0.914583 3.30177 0.860296 3.09943C0.806009 2.89708 0.834326 2.68146 0.939017 2.5C1.04371 2.31854 1.2162 2.18609 1.41854 2.13181C1.62088 2.07752 1.8365 2.10584 2.01796 2.21053L4.48007 3.63158V0.789474C4.48007 0.580092 4.56325 0.379286 4.7113 0.231231C4.85936 0.0831764 5.06016 0 5.26954 0Z"
        fill="currentColor" />
    </svg>
  );
}

import { useEffect, useRef, useState } from 'react';
import { User } from '@common/auth/user';
import { useLogout } from '@common/auth/requests/logout';
import { useUser } from '@common/auth/ui/use-user';

export default function useITONavbar () {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isNotificationDropdownOpen, setIsNotificationDropdownOpen] = useState(false);
  const [user, setUser] = useState<User | null>(null);
  const fullName = `${user?.first_name ?? ''} ${user?.last_name ?? ''}`;
  const logout = useLogout();
  const hasUnreadNotif = !!user?.unread_notifications_count;
  const { data, isLoading } = useUser('me', {
    with: ['roles', 'social_profiles', 'tokens'],
  });
  const profileDropdownRef = useRef<HTMLDivElement | null>(null);
  const notificationDropdownRef = useRef<HTMLDivElement | null>(null);

  const toggleDropdown = () => {
    setIsDropdownOpen(prevState => !prevState);
  };

  const toggleNotificationDropdown = () => {
    setIsNotificationDropdownOpen(prevState => !prevState);
  };

  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      if (
        profileDropdownRef.current &&
        !profileDropdownRef.current.contains(event.target as Node)
      ) {
        setIsDropdownOpen(false);
      }

      if (
        notificationDropdownRef.current &&
        !notificationDropdownRef.current.contains(event.target as Node)
      ) {
        setIsNotificationDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (!isLoading && data?.user) {
      setUser(data.user);
    }
  }, [isLoading, data]);

  return {
    user,
    fullName,
    isDropdownOpen,
    isNotificationDropdownOpen,
    profileDropdownRef,
    notificationDropdownRef,
    hasUnreadNotif,
    logout,
    toggleDropdown,
    toggleNotificationDropdown,
    setIsNotificationDropdownOpen,
  };
}

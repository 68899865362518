import {Trans} from '@common/i18n/trans';
import {ReactNode} from 'react';
import {
  SocialService
} from '@common/auth/requests/use-social-login';
import {User} from '@common/auth/user';
import {useSettings} from '@common/core/settings/use-settings';

import {
  SocialLoginPanelRowButton
} from '@common/auth/ui/account-settings/social-login-panel';

interface Props {
  icon: ReactNode;
  service: SocialService;
  user: User;
}

export function SocialLoginPanelRow({icon, service, user}: Props) {
  const {social} = useSettings();
  const username = user?.social_profiles?.find(s => s.service_name === service)
    ?.username;

  if (!social?.[service]?.enable) {
    return null;
  }

  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center gap-2">
        <div className="rounded border p-1">
          {icon}
        </div>
        <div>
          <h3 className="text-sm text-black capitalize">
            <Trans message=":service account" values={{service}} />
          </h3>
          <div className="text-gray-500 text-xs">
            {username || <Trans message="Disabled" />}
          </div>
        </div>
      </div>
      <SocialLoginPanelRowButton
        username={username}
        service={service}
        className="bg-purple-800 hover:bg-purple-800 rounded-lg px-4 py-2 text-sm text-white"
      />
    </div>
  )
}

import {AccountSettingsId} from '@common/ui/layout/ito/sidebar/sidebar';
import {Trans} from '@common/i18n/trans';

export function ITO_DatetimeAndLanguagePanel() {
  return (
    <section
      id={AccountSettingsId.LocationAndLanguage}
      className="border-gray-300 mb-6 rounded-lg border-b bg-white p-6 shadow">
      <h2 className="mb-4 border-b pb-2 text-lg font-semibold">
        <Trans message="Date, time and language" />
      </h2>
      <label className="mb-2 block text-sm">
        <Trans message="Language" />
      </label>
      <input
        type="text"
        placeholder="English"
        className="border-gray-300 px-2.5 py-1.5 mb-4 w-full rounded border"
      />
      <label className="mb-2 block text-sm">
        <Trans message="Country" />
      </label>
      <input
        type="text"
        placeholder="United States"
        className="border-gray-300 px-2.5 py-1.5 mb-4 w-full rounded border"
      />
      <label className="mb-2 block text-sm">
        <Trans message="Timezone" />
      </label>
      <input
        type="text"
        placeholder="Los Angeles"
        className="border-gray-300 px-2.5 py-1.5 mb-4 w-full rounded border"
      />
      <button
        className="bg-purple-800 hover:bg-purple-800 mt-4 rounded-lg px-5 py-2 text-sm text-white">
        <Trans message="Save" />
      </button>
    </section>
  )
}

import { ReactNode } from "react";
import { Link } from 'react-router-dom';
import {Trans} from '@common/i18n/trans';
import { Item, AccountSettingsId } from "./account-settings-sidenav";

interface Props {
  id: AccountSettingsId;
  icon: ReactNode;
  message: string
  to?: string;
}

export function SitenavLink({id, icon, to, message}: Props) {
  return (
    to ?
      <Link to={`${to}#${id}`}>
        <Item icon={icon} panel={id}>
          <Trans message={message} />
        </Item>
      </Link>
      :
      <Item icon={icon} panel={id}>
        <Trans message={message} />
      </Item>
  )
}

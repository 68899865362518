export function CrossInCircleIcon () {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.5 9C16.5 9.98491 16.306 10.9602 15.9291 11.8701C15.5522 12.7801 14.9997 13.6069 14.3033 14.3033C13.6069 14.9997 12.7801 15.5522 11.8701 15.9291C10.9602 16.306 9.98491 16.5 9 16.5C8.01509 16.5 7.03982 16.306 6.12987 15.9291C5.21993 15.5522 4.39314 14.9997 3.6967 14.3033C3.00026 13.6069 2.44781 12.7801 2.0709 11.8701C1.69399 10.9602 1.5 9.98491 1.5 9C1.5 7.01088 2.29018 5.10322 3.6967 3.6967C5.10322 2.29018 7.01088 1.5 9 1.5C10.9891 1.5 12.8968 2.29018 14.3033 3.6967C15.7098 5.10322 16.5 7.01088 16.5 9Z"
        stroke="currentColor" strokeWidth="1.25" strokeLinecap="round"
        strokeLinejoin="round" />
      <path
        d="M12.1556 6.67747C12.2112 6.62369 12.2555 6.55935 12.286 6.4882C12.3165 6.41705 12.3326 6.34052 12.3333 6.26307C12.334 6.18562 12.3194 6.10881 12.2902 6.03711C12.261 5.96541 12.2178 5.90026 12.1632 5.84547C12.1087 5.79068 12.0438 5.74734 11.9723 5.71797C11.9009 5.68861 11.8243 5.67382 11.7471 5.67445C11.6699 5.67509 11.5936 5.69114 11.5226 5.72168C11.4517 5.75222 11.3875 5.79662 11.3338 5.85231L9.00331 8.18937L6.67356 5.85231C6.62033 5.795 6.55614 5.74903 6.48481 5.71715C6.41348 5.68527 6.33649 5.66813 6.25841 5.66675C6.18034 5.66537 6.10279 5.67977 6.03038 5.70911C5.95798 5.73845 5.89221 5.78211 5.837 5.8375C5.78178 5.89289 5.73825 5.95887 5.70901 6.0315C5.67976 6.10413 5.6654 6.18192 5.66678 6.26024C5.66816 6.33856 5.68524 6.4158 5.71702 6.48735C5.74881 6.5589 5.79463 6.6233 5.85176 6.6767L8.17995 9.01454L5.8502 11.3516C5.74749 11.4622 5.69158 11.6084 5.69424 11.7595C5.69689 11.9107 5.75792 12.0548 5.86445 12.1617C5.97099 12.2686 6.11472 12.3298 6.26536 12.3325C6.416 12.3351 6.56179 12.279 6.67201 12.176L9.00331 9.83893L11.3331 12.1768C11.4433 12.2798 11.5891 12.3359 11.7397 12.3332C11.8904 12.3306 12.0341 12.2694 12.1406 12.1625C12.2472 12.0556 12.3082 11.9114 12.3108 11.7603C12.3135 11.6092 12.2576 11.463 12.1549 11.3524L9.82667 9.01454L12.1556 6.67747Z"
        fill="currentColor" />
    </svg>
  );
}

import {AccountSettingsId} from '@common/ui/layout/ito/sidebar/sidebar';
import {Trans} from '@common/i18n/trans';

export function ITO_TwoFactorAuthenticationPanel() {
  return (
    <section
      id={AccountSettingsId.TwoFactor}
      className="border-gray-300 mb-6 rounded-lg border-b bg-white p-6 shadow">
      <h2 className="mb-4 border-b pb-2 text-lg font-semibold">
        <Trans message="Two-factor authentication" />
      </h2>
      <p className="text-gray-700 mb-2 text-sm">
        <Trans message="You have not enabled two-factor authentication." />
      </p>
      <p className="text-gray-500 mb-4 text-sm">
        <Trans message="When two-factor authentication is enabled, you will be prompted for
        a secure, random token during authentication. You may retrieve this
        token from your phone's Google Authenticator application." />
      </p>
      <button
        className="text-purple-800 bg-gray-100 hover:bg-gray-300 rounded-lg px-6 py-2 text-sm">
        <Trans message="Enable" />
      </button>
    </section>
  )
}

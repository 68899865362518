import React, {useState} from 'react';
import {ConnectIcon} from '@common/auth/ui/ito/icons/connect-icon';
import {
  PaymentMethodRow
} from '@common/auth/ui/ito/payments/gateway/payment-method-row';

export interface PaymentGatewayBasicItems {
  name: string;
  imgSrc: string;
}

interface GatewayProvider {
  name: string;
}


export function ITO_ChoosePaymentGatewayPage() {
  const [gatewayProviders, setGatewayProviders] = useState<GatewayProvider[]>([
    {
      name: 'Stripe',
    },
    {
      name: 'PayPal',
    },
    {
      name: 'Authorize.net',
    },
    {
      name: 'GoCardLess',
    },
    {
      name: 'RazorPay',
    },
    {
      name: 'FlutterWave',
    },
    {
      name: 'RazorPay',
    },
    {
      name: 'MercadoPago',
    },
    {
      name: 'Instamojo',
    },
    {
      name: 'SecurePay',
    },
    {
      name: 'AmazonPay',
    },
  ])
  const [paymentGateways, setPaymentGateways] = useState<PaymentGatewayBasicItems[]>([
    {name: 'Authorize.net', imgSrc: '/images/icons/authorize-dot-net.svg'},
    {name: 'Stripe', imgSrc: '/images/icons/stripe.svg'},
    {name: 'PayPal', imgSrc: '/images/icons/paypal.png'},
    {name: 'GoCardLess', imgSrc: '/images/icons/go-card-less.png'},
    {name: 'RazorPay', imgSrc: '/images/icons/razorpay.png'},
    {name: 'FlutterWave', imgSrc: '/images/icons/flutter-wave.png'},
    {name: 'RazorPay', imgSrc: '/images/icons/razorpay.png'},
    {name: 'MercadoPago', imgSrc: '/images/icons/mercado-pago.png'},
    {name: 'Instamojo', imgSrc: '/images/icons/instamojo.png'},
    {name: 'SecurePay', imgSrc: '/images/icons/secure-pay.png'},
    {name: 'AmazonPay', imgSrc: '/images/icons/amazon-pay.png'},
    {name: 'Maverick', imgSrc: '/images/icons/maverick.png'},
    {name: 'Adyen', imgSrc: '/images/icons/adyen.png'},
    // Add more items as needed
  ]);

  return (
    <div className="">
      <header>
        <h1 className="mb-2 text-2xl font-semibold">
          Choose The Payment Gateway You Want To Use To Get Paid
        </h1>
        <p className="text-gray-500 text-sm  mb-6">
          When you're ready to charge your customers, you'll use a payment
          gateway for the transaction. Card details are always stored securely
          in the PCI DSS compliant ReSkillify vault, so you can change gateways
          at any time without needing to migrate card data.
        </p>
      </header>

      {/* Add Payment Gateway Section */}
      <section className="mb-8 px-5 py-4 rounded-lg bg-white shadow-sm">
        <header>
          <h2 className="border-gray-100 text-md mb-3 border-b pb-1 font-semibold">
            Add Payment Gateway
          </h2>
        </header>
        <main>
          <label htmlFor="provider" className="text-sm">
            Provider
          </label>
          <select
            id="provider"
            className="border-gray-300 text-gray-500 focus:ring-purple-500 focus:border-purple-500 mt-1 block w-full rounded-lg border p-3 text-sm shadow-sm"
          >
            <option>Select Provider</option>
            {gatewayProviders.map((provider: GatewayProvider) => (
              <option key={provider.name}>{provider.name}</option>
            ))}
          </select>
        </main>
      </section>

      <section className="mb-6 px-5 py-4 rounded-lg bg-white shadow-sm">
        <header>
          <h2 className="border-gray-100 text-md border-b pb-1 font-semibold">
            Payment Method
          </h2>
        </header>
        <main>
          {paymentGateways.map((gateway: PaymentGatewayBasicItems) => (
            <PaymentMethodRow
              gateway={gateway}
            />
          ))}
        </main>
      </section>

      {/* Divider */}
      <hr className="border-gray-300 mb-6" />

      <section>
        {/* Text Section */}
        <h2 className="text-gray-500 text-sm mb-2 mt-6">Don't have a payment gateway?</h2>
        {/* Stripe Recommendation Section */}
        <div className="flex flex-col lg:flex-row items-center justify-between rounded-lg bg-white p-4 shadow-sm">
          <div className="flex items-center w-full lg:w-auto">
            <img
              src="/images/icons/strip-s-square.png"
              alt="Stripe"
              className="mr-3 h-9"
            />
            <div>
              <p className="text-sm font-semibold">Stripe</p>
              <p className="text-gray-500 text-xs">
                If you haven't got a payment gateway, Stripe makes it really easy
                to get started
              </p>
            </div>
          </div>
          <div className="flex justify-start items-start w-full mt-5 lg:mt-0 lg:w-auto lg:items-center space-x-2">
            <button
              className="bg-purple-800 rounded-lg px-4 py-2 text-sm font-medium text-white border-2 border-transparent hover:border-purple-800 hover:bg-transparent hover:text-purple-800">
              Recommended
            </button>
            <button
              className="bg-[#EEE6F4] text-[#590494] flex items-center rounded-lg px-4 py-2 text-sm font-medium hover:bg-purple-800 hover:text-white">
              +
            </button>
          </div>
        </div>
      </section>
    </div>
  );
}

import {AccountSettingsId} from '@common/ui/layout/ito/sidebar/sidebar';
import {Trans} from '@common/i18n/trans';
import React, {useId} from 'react';
import {Form, useForm} from 'react-hook-form';
import {
  UpdatePasswordPayload, useUpdatePassword
} from '@common/auth/ui/account-settings/change-password-panel/use-update-password';
import InputField
  from '@common/auth/ui/ito/account-settings/change-password-panel/input-field';
import SubmitButton
  from '@common/auth/ui/ito/account-settings/change-password-panel/submit-button';

export function ITO_ChangePasswordPanel() {
  const form = useForm<UpdatePasswordPayload>({
    defaultValues: {
      current_password: '',
      password: '',
      password_confirmation: '',
    },
  });
  const formId = useId();
  const updatePassword = useUpdatePassword(form);

  // @ts-ignore
  const onFormSubmit = (newValues) => {
    updatePassword.mutate(newValues.data, {
      onSuccess: () => {
        form.reset();
      }
    });
  }

  return (
    <section
      id={AccountSettingsId.Password}
      className="border-gray-300 mb-6 rounded-lg border-b bg-white p-6 shadow"
    >
      <Form
        // @ts-ignore
        form={form}
        control={form.control}
        id={formId}
        onSubmit={onFormSubmit}
      >
        <h2 className="mb-4 border-b pb-2 text-lg font-semibold">
          <Trans message="Update Password" />
        </h2>
        <InputField
          label="Current Password"
          name="current_password"
          register={form.register}
          required="Required"
          errors={form.formState.errors}
          type="password"
          placeholder="Enter Current Password"
          autoComplete="current-password"
        />
        <InputField
          label="New Password"
          name="password"
          register={form.register}
          required="Required"
          errors={form.formState.errors}
          type="password"
          placeholder="Enter New Password"
          autoComplete="new-password"
        />
        <InputField
          label="Confirm Password"
          name="password_confirmation"
          register={form.register}
          required="Required"
          errors={form.formState.errors}
          type="password"
          placeholder="Re-enter New Password"
          autoComplete="new-password"
        />
        <SubmitButton
          isDisabled={!form.formState.isValid || !form.formState.isDirty || updatePassword.isPending}
          message="Update Password"
        />
      </Form>
    </section>
  );
}

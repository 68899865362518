import React from 'react';
import { ArrowDirection, ArrowIcon } from '@common/auth/ui/ito/icons/arrow-icon';
import {Trans} from '@common/i18n/trans';

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({ currentPage, totalPages, onPageChange }) => {
  const maxButtonsToShow = 3; // Change to 3 for displaying only 3 numbers at the start and end
  const generatePageNumbers = () => {
    let pages = [];

    if (totalPages <= maxButtonsToShow + 2) {
      // Show all pages if they fit within the limit
      pages = Array.from({ length: totalPages }, (_, i) => i + 1);
    } else {
      // Create pagination with ellipsis
      if (currentPage <= maxButtonsToShow) {
        // Close to the beginning
        pages = [1, 2, 3, '...', totalPages - 2, totalPages - 1, totalPages];
      } else if (currentPage >= totalPages - (maxButtonsToShow - 1)) {
        // Close to the end
        pages = [1, 2, 3, '...', totalPages - 2, totalPages - 1, totalPages];
      } else {
        // Middle pages
        pages = [1, '...', currentPage - 1, currentPage, currentPage + 1, '...', totalPages - 2, totalPages - 1, totalPages];
      }
    }

    return pages;
  };

  const handlePageChange = (page: number | string) => {
    if (typeof page === 'number' && page !== currentPage) {
      onPageChange(page);
    }
  };

  const isFirstPage = (): boolean => {
    return currentPage === 1;
  }

  const isLastPage = (): boolean => {
    return currentPage === totalPages
  }

  return (
    <footer className="mt-4 flex justify-between">
      <button
        className={
        `border-gray-300 flex items-center justify-evenly rounded-lg border px-3 py-2 text-sm font-semibold ${isFirstPage() ? 'text-gray-300' : 'text-gray-700 hover:text-white hover:bg-purple-800'}`
      }
        onClick={() => handlePageChange(currentPage - 1)}
        disabled={isFirstPage()}
      >
        <ArrowIcon />
        <span className="ml-2">
          <Trans message='Previous' />
        </span>
      </button>

      <div className="hidden lg:flex space-x-1">
        {generatePageNumbers().map((page, index) =>
          typeof page === 'number' ? (
            <button
              key={index}
              onClick={() => handlePageChange(page)}
              className={`rounded px-3 py-1 text-sm hover:font-semibold ${
                page === currentPage ? 'font-semibold' : ''
              }`}
            >
              <Trans message={page?.toString()} />
            </button>
          ) : (
            <div
              key={index}
              className="dots inline-block translate-y-1/4 px-5 text-[18px] leading-none"
            >
              ...
            </div>
          ),
        )}
      </div>
      <div className="lg:hidden rounded px-3 py-1 text-sm font-semibold">{currentPage}</div>

      <button
        className={
          `border-gray-300 flex items-center justify-evenly rounded-lg border px-3 py-2 text-sm font-semibold ${isLastPage() ? 'text-gray-300' : 'text-gray-700 hover:text-white hover:bg-purple-800'}`
        }
        onClick={() => handlePageChange(currentPage + 1)}
        disabled={isLastPage()}
      >
        <span className="mr-2">
          <Trans message='Next' />
        </span>
        <ArrowIcon direction={ArrowDirection.Right} />
      </button>
    </footer>
  );
};

export default Pagination;

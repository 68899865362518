export default function FacebookIcon() {
  return (
    <svg
      width="25"
      height="26"
      viewBox="0 0 25 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25 12.5398C25 5.63628 19.4035 0.0397949 12.5 0.0397949C5.59648 0.0397949 0 5.63628 0 12.5398C0 18.7789 4.57109 23.9502 10.5469 24.8879V16.1531H7.37305V12.5398H10.5469V9.78589C10.5469 6.65308 12.4131 4.92261 15.2684 4.92261C16.6359 4.92261 18.0664 5.16675 18.0664 5.16675V8.24292H16.4902C14.9374 8.24292 14.4531 9.20649 14.4531 10.1951V12.5398H17.9199L17.3657 16.1531H14.4531V24.8879C20.4289 23.9502 25 18.779 25 12.5398Z"
        fill="#1877F2"
      />
      <path
        d="M17.3657 16.1531L17.9199 12.5398H14.4531V10.1951C14.4531 9.2064 14.9374 8.24292 16.4902 8.24292H18.0664V5.16675C18.0664 5.16675 16.6359 4.92261 15.2683 4.92261C12.4131 4.92261 10.5469 6.65308 10.5469 9.78589V12.5398H7.37305V16.1531H10.5469V24.8879C11.193 24.9892 11.846 25.04 12.5 25.0398C13.154 25.04 13.807 24.9892 14.4531 24.8879V16.1531H17.3657Z"
        fill="white"
      />
    </svg>
  )
}

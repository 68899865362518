import React from 'react';

export default function DollarIcon() {
  return (
    <svg
      width="12"
      height="20"
      viewBox="0 0 12 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 0.833332V19.1667M10.1667 4.16667H3.91667C3.14312 4.16667 2.40125 4.47396 1.85427 5.02094C1.30729 5.56792 1 6.30978 1 7.08333C1 7.85688 1.30729 8.59874 1.85427 9.14573C2.40125 9.69271 3.14312 10 3.91667 10H8.08333C8.85688 10 9.59875 10.3073 10.1457 10.8543C10.6927 11.4013 11 12.1431 11 12.9167C11 13.6902 10.6927 14.4321 10.1457 14.9791C9.59875 15.526 8.85688 15.8333 8.08333 15.8333H1"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

import {StarIcon} from '@common/auth/ui/ito/icons/star-icon';
import {ExclamationMarkInCircleIcon} from '@common/auth/ui/ito/icons/exclamation-mark-in-circle-icon';
import {CrossInCircleIcon} from '@common/auth/ui/ito/icons/cross-in-circle-icon';
import {ChatIcon} from '@common/auth/ui/ito/icons/chat-icon';
import {PencilIcon} from '@common/auth/ui/ito/icons/pencil-icon';
import {TrashIcon} from '@common/auth/ui/ito/icons/trash-icon';
import {UpDownArrowsIcon} from '@common/auth/ui/ito/icons/up-down-arrows-icon';
import Breadcrumb from '@common/auth/ui/ito/breadcrumb';
import {useState} from 'react';
import Pagination from '@common/auth/ui/ito/pagination';
import {Trans} from '@common/i18n/trans';
import {Link} from 'react-router-dom';

export enum PaymentGatewayMode {
  live = 'Live',
  sandbox = 'Sandbox',
}

export enum PaymentGatewayStatus {
  enabled = 'Enabled',
  disabled = 'Disabled',
}

interface PaymentGatewayType {
  id: number;
  name: string;
  default: boolean;
  title: string;
  brandName: string;
  mode: PaymentGatewayMode;
  status: PaymentGatewayStatus;
}

export function ITO_PaymentGatewayPage() {
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = 10; // Set the total number of pages
  const [paymentGateways, setPaymentGateways] = useState<PaymentGatewayType[]>([
    {
      id: 1,
      name: 'Main Stripe',
      default: true,
      title: 'Stripe',
      brandName: 'ReSkillify',
      mode: PaymentGatewayMode.live,
      status: PaymentGatewayStatus.enabled,
    },
    {
      id: 2,
      name: 'Backup Paypal',
      default: false,
      title: 'PayPal',
      brandName: 'ABC Inc',
      mode: PaymentGatewayMode.sandbox,
      status: PaymentGatewayStatus.disabled,
    },
    {
      id: 3,
      name: 'Authorize.net',
      default: false,
      title: 'Authorize.net',
      brandName: 'TCJB',
      mode: PaymentGatewayMode.live,
      status: PaymentGatewayStatus.enabled,
    },
    {
      id: 4,
      name: 'RazorPay',
      default: false,
      title: 'Razorpay',
      brandName: 'ABC Inc',
      mode: PaymentGatewayMode.sandbox,
      status: PaymentGatewayStatus.disabled,
    },
    {
      id: 5,
      name: 'RazorPay',
      default: false,
      title: 'Secure Pay',
      brandName: 'ReSkillify',
      mode: PaymentGatewayMode.live,
      status: PaymentGatewayStatus.enabled,
    },
    {
      id: 6,
      name: 'Amazon Pay',
      default: false,
      title: 'Amazon Pay',
      brandName: 'TCJB',
      mode: PaymentGatewayMode.sandbox,
      status: PaymentGatewayStatus.disabled,
    },
    {
      id: 7,
      name: 'Authorize.net',
      default: false,
      title: 'Authorize.net',
      brandName: 'ABC Ince',
      mode: PaymentGatewayMode.live,
      status: PaymentGatewayStatus.enabled,
    },
    {
      id: 8,
      name: 'Main Stripe',
      default: false,
      title: 'Stripe',
      brandName: 'ReSkillify',
      mode: PaymentGatewayMode.sandbox,
      status: PaymentGatewayStatus.disabled,
    },
    {
      id: 9,
      name: 'Main Stripe',
      default: false,
      title: 'Stripe',
      brandName: 'TCJB',
      mode: PaymentGatewayMode.live,
      status: PaymentGatewayStatus.enabled,
    },
    {
      id: 10,
      name: 'RazorPay',
      default: false,
      title: 'Secure Pay',
      brandName: 'ReSkillify',
      mode: PaymentGatewayMode.sandbox,
      status: PaymentGatewayStatus.disabled,
    },
  ]);
  const breadcrumbItems = [
    {label: 'Payment Gateway', path: '/ito/account-settings'},
  ];

  const enabled = (status: PaymentGatewayStatus): boolean => {
    return status === PaymentGatewayStatus.enabled;
  };

  // Function to handle page changes
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <>
      <Breadcrumb items={breadcrumbItems}/>

      <header className="mt-7 mb-6 flex items-center justify-between">
        <div>
          <h2 className="text-xl font-semibold text-black">
            <Trans message='Payment Gateway Overview' />
          </h2>
          <p className="text-gray-500 text-sm">
            <Trans message='See Our Payment Gateways' />
          </p>
        </div>
        <div className="flex space-x-2">
          <Link to='/ito/payments/gateway/create' className="border-purple-800 text-purple-800 py-1.5 hover:bg-purple-900 rounded-md border px-3 text-sm font-semibold hover:text-white">
            <Trans message='+ Create Gateway' />
          </Link>
          <Link to='/ito/payments/gateway/create' className="bg-purple-800 hover:bg-purple-900 py-1.5 rounded-md px-3 text-sm font-semibold text-white">
            <Trans message='+ Add Gateway' />
          </Link>
        </div>
      </header>
      {/*min-w-full*/}
      <main className="border-gray-200 max-w-full overflow-x-auto rounded-lg border">
        <table className="border-gray-200 w-full border bg-white">
          <thead className="bg-gray-100 text-sm text-black">
            <tr>
              <th className="text-gray-500 px-4 py-3 text-left font-medium">
                <div className="flex items-center justify-between">
                  <div className="flex items-center mb-[0.125rem] min-h-[1.5rem] ps-[1.5rem]">
                    <input
                      className="border-secondary-500 before:shadow-checkbox checked:focus:before:shadow-checkbox dark:border-neutral-400 relative float-left -ms-[1.5rem] me-[6px] h-[1.125rem] w-[1.125rem] appearance-none rounded-[0.25rem] border-[0.125rem] border-solid outline-none before:pointer-events-none before:absolute before:h-[0.875rem] before:w-[0.875rem] before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-transparent before:content-[''] checked:border-primary checked:bg-primary checked:before:opacity-[0.16] checked:after:absolute checked:after:-mt-px checked:after:ms-[0.25rem] checked:after:block checked:after:h-[0.8125rem] checked:after:w-[0.375rem] checked:after:rotate-45 checked:after:border-[0.125rem] checked:after:border-l-0 checked:after:border-t-0 checked:after:border-solid checked:after:border-white checked:after:bg-transparent checked:after:content-[''] hover:cursor-pointer hover:before:opacity-[0.04] hover:before:shadow-black/60 focus:shadow-none focus:transition-[border-color_0.2s] focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-black/60 focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-[0.875rem] focus:after:w-[0.875rem] focus:after:rounded-[0.125rem] focus:after:content-[''] checked:focus:before:scale-100 checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] checked:focus:after:-mt-px checked:focus:after:ms-[0.25rem] checked:focus:after:h-[0.8125rem] checked:focus:after:w-[0.375rem] checked:focus:after:rotate-45 checked:focus:after:rounded-none checked:focus:after:border-[0.125rem] checked:focus:after:border-l-0 checked:focus:after:border-t-0 checked:focus:after:border-solid checked:focus:after:border-white checked:focus:after:bg-transparent dark:checked:border-primary dark:checked:bg-primary rtl:float-right"
                      type="checkbox"
                      value=""
                      id="checkboxDefault"
                    />
                    <label
                      className="inline-block ps-[0.15rem] hover:cursor-pointer"
                      htmlFor="checkboxDefault"
                    >
                      <Trans message='Gateway Name' />
                    </label>
                  </div>
                  <UpDownArrowsIcon />
                </div>
              </th>
              <th className="text-gray-500 px-4 py-3 text-left font-medium">
                <div className="flex items-center justify-between">
                  <Trans message='Payment Gateway' />
                  <UpDownArrowsIcon />
                </div>
              </th>
              <th className="text-gray-500 px-4 py-3 text-left font-medium">
                <div className="flex items-center justify-between">
                  <Trans message='Brand Name' />
                  <UpDownArrowsIcon />
                </div>
              </th>
              <th className="text-gray-500 px-4 py-3 text-left font-medium">
                <div className="flex items-center justify-between">
                  <Trans message='Mode' />
                  <UpDownArrowsIcon />
                </div>
              </th>
              <th className="text-gray-500 px-4 py-3 text-left font-medium">
                <div className="flex items-center justify-between">
                  <Trans message='Status' />
                  <UpDownArrowsIcon />
                </div>
              </th>
              <th className="text-gray-500 px-4 py-3 text-left font-medium">
                <div className="flex items-center justify-between">
                  <Trans message='Actions' />
                  <UpDownArrowsIcon />
                </div>
              </th>
            </tr>
          </thead>
          <tbody className="text-sm text-black">
            {paymentGateways.map(paymentGateway => (
              <tr
                key={paymentGateway.id}
                className="text-gray-500 border-b hover:cursor-pointer hover:text-[#590494]"
              >
                <td className="px-4 py-5">
                  <div className="flex items-center justify-between">
                    <span>
                      {paymentGateway.name}
                    </span>
                    {paymentGateway.default && (
                      <span className="ml-2 rounded-md border bg-[#EEE6F4] px-2 py-1 text-xs font-semibold text-[#9057B7]">
                        <Trans message='Default' />
                      </span>
                    )}
                  </div>
                </td>
                <td className="px-4 py-5">{paymentGateway.title}</td>
                <td className="px-4 py-5">{paymentGateway.brandName}</td>
                <td className="px-4 py-5 capitalize">{paymentGateway.mode}</td>
                <td className="px-4 py-5">
                  <button className="px-1.5 py-0.5 inline-flex items-center justify-around rounded-md border text-xs">
                    <span
                      className={`mr-1 inline-block h-2 w-2 rounded-full ${
                        enabled(paymentGateway.status)
                          ? 'bg-green-500'
                          : 'bg-red-500'
                      }`}
                    ></span>
                    <span className="capitalize">{paymentGateway.status}</span>
                  </button>
                </td>
                <td className="px-4 py-5">
                  <div className="flex justify-evenly space-x-2">
                    <button
                      className={`h-5 w-5 ${
                        enabled(paymentGateway.status)
                          ? 'text-green-500'
                          : 'text-gray-400'
                      }`}
                    >
                      <StarIcon />
                    </button>
                    <button
                      className={`h-5 w-5 ${
                        enabled(paymentGateway.status)
                          ? 'text-yellow-500'
                          : 'text-gray-400'
                      }`}
                    >
                      <ExclamationMarkInCircleIcon />
                    </button>
                    <button
                      className={`h-5 w-5 ${
                        enabled(paymentGateway.status)
                          ? 'text-gray-500'
                          : 'text-red-500'
                      }`}
                    >
                      <CrossInCircleIcon />
                    </button>
                    <button
                      className={`h-5 w-5 ${
                        enabled(paymentGateway.status)
                          ? 'text-yellow-500'
                          : 'text-gray-400'
                      }`}
                    >
                      <ChatIcon />
                    </button>
                    <button className="text-blue-400 h-5 w-5">
                      <PencilIcon />
                    </button>
                    <button className="text-red-500 h-5 w-5">
                      <TrashIcon />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </main>
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />
    </>
  );
}

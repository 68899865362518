export default function TwitterIcon() {
  return (
    <svg
      width="23"
      height="21"
      viewBox="0 0 23 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.1125 0.117523H21.6397L13.9347 8.94624L23 20.9621H15.9029L10.3401 13.676L3.98229 20.9621H0.451786L8.69236 11.5157L0 0.119166H7.27786L12.2984 6.77767L18.1125 0.117523ZM16.8721 18.8461H18.8271L6.21 2.12345H4.11371L16.8721 18.8461Z"
        fill="black"
      />
    </svg>
  )
}

import {Prop} from '@common/auth/ui/account-settings/account-setting-sidebar-link';
import {Link, useLocation} from 'react-router-dom';
import {AccountSettingsId} from '@common/ui/layout/ito/sidebar/sidebar';
import React, {ReactElement} from 'react';
import {Trans} from '@common/i18n/trans';

export default function AccountSettingSidebarDropdownLink({to, goto, Icon, title}: Prop) {
  return (
    <SidebarNav
      to={to}
      goto={goto}
      Icon={Icon}
      title={title}
      hoverClassName='hover:bg-[#EEE6F4] hover:text-[#9057B7]'
      activeClassName='bg-[#EEE6F4] text-[#9057B7]'
    />
  )
}

export function SidebarNav({ goto, to, Icon, title, hoverClassName, activeClassName }: SideNavParamType) {
  const location = useLocation();
  const isActive = to && location.pathname === to;
  const hoverClasses = `${hoverClassName ? hoverClassName : 'hover:bg-purple-800 hover:text-white'}`
  const activeClasses = `${isActive && (activeClassName ? activeClassName : 'bg-purple-800 text-white')}`;

  return (
    <Link
      to={to || ''}
      onClick={() => {
        if (!goto || to) {
          return;
        }

        const panelEl = document.querySelector(`#${goto}`);
        if (panelEl) {
          panelEl.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
        }
      }}
      className={`py-3 px-4 rounded-lg flex items-center text-sm ${hoverClasses} ${activeClasses}`}
    >
      <span className='mr-1.5'>
        {Icon}
      </span>
      <Trans message={title} />
    </Link>
  );
}

interface SideNavParamType {
  to?: string;
  goto?: AccountSettingsId;
  Icon: ReactElement;
  title: string;
  hoverClassName?: string;
  activeClassName?: string;
}

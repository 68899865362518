import { Link } from 'react-router-dom';
import {Trans} from '@common/i18n/trans';
import SidebarTogglerIcon from '@common/ui/layout/ito/navbar/sidebar-toggler-icon';
import BellIcon from '@common/ui/layout/ito/navbar/bell-icon';
import ZeroNotificationsIcon
  from '@common/ui/layout/ito/navbar/zero-notifications-icon';
import AngleBottomIcon from '@common/ui/layout/ito/navbar/angle-bottom-icon';
import useITONavbar from '@common/ui/layout/ito/navbar/navbar';

interface NavbarProps {
  onToggleSidebar: () => void;
}

export function ITO_Navbar({ onToggleSidebar }: NavbarProps) {
  const {
    user,
    fullName,
    isDropdownOpen,
    isNotificationDropdownOpen,
    profileDropdownRef,
    notificationDropdownRef,
    hasUnreadNotif,
    logout,
    toggleDropdown,
    toggleNotificationDropdown,
    setIsNotificationDropdownOpen,
  } = useITONavbar();

  return (
    <div className="flex items-center justify-between border-b bg-white px-4 py-2 shadow-sm">
      <div
        onClick={onToggleSidebar}
        className="hover:text-purple-800 flex cursor-pointer items-center"
      >
        <div className="mr-1">
          <SidebarTogglerIcon />
        </div>
        <span className="text-md ml-2 font-semibold">
          <Trans message='Dashboard' />
        </span>
      </div>

      {/* User Profile & Notification */}
      <aside className="relative flex items-center">
        <section className="">
          <button
            onClick={toggleNotificationDropdown}
            className="relative p-2 focus:outline-none"
          >
            {hasUnreadNotif &&
              <span className="bg-red-500 h-3.5 w-3.5 absolute right-1 top-1 rounded-full border-3 border-white"></span>
            }
            <BellIcon />
          </button>

          {/* Notification Dropdown */}
          {isNotificationDropdownOpen && (
            <div
              ref={notificationDropdownRef}
              className="border-gray-200 absolute right-0 z-30 mt-2 w-96 rounded-md border bg-white shadow-lg"
            >
              <header className="mb-4 flex items-center justify-between border-b-2 px-4 py-3">
                <span className="text-md font-semibold">
                  <Trans message='Notifications' />
                  {hasUnreadNotif ? ` (${user?.unread_notifications_count})` : undefined}
                </span>
                <button
                  onClick={() => setIsNotificationDropdownOpen(false)}
                  className="text-gray-500 hover:text-gray-700 text-2xl focus:outline-none"
                >
                  &times;
                </button>
              </header>

              {/* Illustration & Message */}
              <div className="flex flex-col items-center justify-center px-6 py-4">
                <div className="mb-4">
                  <ZeroNotificationsIcon />
                </div>
                <p className="text-gray-600 font-medium">
                  <Trans message='Hang tight!' />
                </p>
                <p className="text-gray-500 text-sm">
                  <Trans message='Notifications will start showing up here soon.' />
                </p>
              </div>
            </div>
          )}
        </section>

        {/* User Avatar */}
        <section className="relative ml-4">
          <button
            id="user-menu-button"
            onClick={toggleDropdown}
            className="flex items-center focus:outline-none"
            type="button"
          >
            <span className="mr-2 text-sm font-medium">{`${fullName}`}</span>
            <img
              className="h-14 w-14 rounded-full shadow"
              src={user?.avatar ? user.avatar : "/images/avatar.png"}
              alt="User Avatar"
            />
            <AngleBottomIcon />
          </button>

          {/* Profile Dropdown */}
          {isDropdownOpen && (
            <div
              ref={profileDropdownRef}
              className="border-gray-200 absolute right-0 z-20 mt-2 w-48 rounded-md border bg-white py-1 shadow-lg"
            >
              <Link
                to="/admin"
                className="text-gray-700 hover:bg-gray-100 block px-4 py-2"
              >
                <Trans message='Admin Area' />
              </Link>
              <Link
                to="/dashboard"
                className="text-gray-700 hover:bg-gray-100 block px-4 py-2"
              >
                <Trans message='Dashboard' />
              </Link>
              <Link
                to="/ito-account-settings"
                className="text-gray-700 hover:bg-gray-100 block px-4 py-2"
              >
                <Trans message='Account Settings' />
              </Link>
              <Link
                to="/ito-account-settings"
                className="text-gray-700 hover:bg-gray-100 block px-4 py-2"
              >
                <Trans message='Dark Mode' />
              </Link>
              <Link
                to="#"
                onClick={() => {
                  logout.mutate();
                }}
                className="text-gray-700 hover:bg-gray-100 block px-4 py-2"
              >
                <Trans message='Log out' />
              </Link>
            </div>
          )}
        </section>
      </aside>
    </div>
  );
}

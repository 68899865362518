import {AccountSettingsId} from '@common/ui/layout/ito/sidebar/sidebar';
import {Trans} from '@common/i18n/trans';

export function ITO_DangerzonePanel() {
  return (
    <div
      id={AccountSettingsId.DeleteAccount}
      className="rounded-lg bg-white p-6 shadow">
      <h2 className="mb-4 border-b pb-2 text-lg font-semibold">
        <Trans message="Danger zone" />
      </h2>
      <button
        className="bg-red-50 text-red-500 hover:bg-red-700 rounded-lg px-4 py-2 text-sm">
        <Trans message="Delete Account" />
      </button>
    </div>
  )
}

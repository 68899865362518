import React from 'react';

export default function TodosIcon() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.16659 4.83333H13.1666M4.83325 4.83333H5.66659M4.83325 8.99999H5.66659M4.83325 13.1667H5.66659M8.16659 8.99999H13.1666M8.16659 13.1667H13.1666M1.08325 8.99999C1.08325 5.26833 1.08325 3.40166 2.24242 2.24249C3.40159 1.08333 5.26742 1.08333 8.99992 1.08333C12.7316 1.08333 14.5983 1.08333 15.7574 2.24249C16.9166 3.40166 16.9166 5.26749 16.9166 8.99999C16.9166 12.7317 16.9166 14.5983 15.7574 15.7575C14.5983 16.9167 12.7324 16.9167 8.99992 16.9167C5.26825 16.9167 3.40159 16.9167 2.24242 15.7575C1.08325 14.5983 1.08325 12.7325 1.08325 8.99999Z"
        stroke="#67717A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

import React from 'react';

export default function MobileTickMarkIcon() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.5 8.16679H6.5M9.8332 8.99999L10.7416 10.0416L12.3336 7.95839M2.3332 4.83319H6.5V13.1668H2.3332C2.22375 13.1668 2.11537 13.1452 2.01426 13.1033C1.91314 13.0614 1.82127 13 1.7439 12.9226C1.66652 12.8452 1.60516 12.7533 1.56331 12.6522C1.52146 12.551 1.49995 12.4426 1.5 12.3332V5.66679C1.5 5.44578 1.58777 5.23381 1.74401 5.07749C1.90026 4.92117 2.11219 4.8333 2.3332 4.83319Z"
        stroke="#67717A"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.5 15.6668V2.3332C16.5 2.11222 16.4122 1.90029 16.256 1.74404C16.0997 1.58778 15.8878 1.5 15.6668 1.5H7.3332C7.11222 1.5 6.90029 1.58778 6.74404 1.74404C6.58778 1.90029 6.5 2.11222 6.5 2.3332V15.6668C6.5 15.8878 6.58778 16.0997 6.74404 16.256C6.90029 16.4122 7.11222 16.5 7.3332 16.5H15.6668C15.8878 16.5 16.0997 16.4122 16.256 16.256C16.4122 16.0997 16.5 15.8878 16.5 15.6668ZM10.25 3.1668H12.75L13.1668 1.5H9.8332L10.25 3.1668Z"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

import {ConnectIcon} from '@common/auth/ui/ito/icons/connect-icon';
import React from 'react';
import {
  PaymentGatewayBasicItems
} from '@common/auth/ui/ito/payments/gateway/choose-payment-gateway';

export function PaymentMethodRow ({gateway}: {gateway: PaymentGatewayBasicItems}) {
  return (
    <div
      key={gateway.name}
      className="py-3 flex flex-col space-y-3 sm:space-y-0 sm:flex-row items-center justify-between rounded-lg border-b"
    >
      <div className="border rounded-md py-2 px-3">
        <img src={gateway.imgSrc} alt={gateway.name} className="w-36 h-9" />
      </div>

      <button
        className="bg-[#EEE6F4] text-[#590494] flex items-center rounded-lg px-3 py-2 text-sm font-medium hover:bg-purple-800 hover:text-white">
        <ConnectIcon width={17} height={17} />
        <span className="ml-1.5">Connect</span>
      </button>
    </div>
  )
}

import React from 'react';

export default function ScreenTickIcon() {
  return (
    <svg
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.500416 0L13.5112 0L14.0117 0.500416V6.008C13.7018 5.77541 13.3659 5.5796 13.0108 5.42451V1.00083H1.00083V10.0083H6.00499C6.00499 11.0911 6.35617 12.1446 7.00583 13.0108H3.0025V12.01H6.00499V11.0092H0.500416L0 10.5087L0 0.500416L0.500416 0Z"
        fill="#67717A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.78535 6.67955C9.22243 6.38423 9.71355 6.1782 10.2305 6.07329C10.7475 5.96839 11.2801 5.96668 11.7977 6.06827C12.3153 6.16987 12.8077 6.37275 13.2467 6.66526C13.6856 6.95778 14.0624 7.33416 14.3555 7.77278C14.6485 8.21139 14.852 8.70359 14.9541 9.22109C15.0563 9.73859 15.0552 10.2712 14.9509 10.7883C14.8466 11.3053 14.6412 11.7967 14.3464 12.2341C14.0516 12.6715 13.6732 13.0464 13.2331 13.3371C12.3502 13.9203 11.2723 14.1302 10.2351 13.9209C9.19784 13.7117 8.28564 13.1004 7.69785 12.2205C7.11006 11.3407 6.89449 10.264 7.09828 9.22565C7.30206 8.18735 7.90862 7.27195 8.78535 6.67955ZM10.9171 11.6737L13.3061 8.48906L12.5054 7.88856L10.4267 10.6599L9.12464 9.61799L8.49912 10.3986L10.2045 11.7638L10.9181 11.6737H10.9171Z"
        fill="currentColor"
      />
    </svg>
  )
}

import React from 'react';

export default function ShieldIcon() {
  return (
    <svg
      width="14"
      height="17"
      viewBox="0 0 14 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.37391 4.95988H10.6261M3.37391 7.25952H7M3.37391 9.55955H4.21734M7.63196 15.7862L11.6526 12.6754C12.0736 12.3497 12.4129 11.9391 12.646 11.4734C12.8791 11.0077 13 10.4986 13 9.98301V3.00254C12.9998 2.77724 12.9213 2.55831 12.7766 2.37971C12.6319 2.20112 12.4292 2.07286 12.1999 2.01484L9.30342 1.28522C7.79393 0.904928 6.20607 0.904928 4.69658 1.28522L1.80008 2.01484C1.57078 2.07286 1.36807 2.20112 1.2234 2.37971C1.07873 2.55831 1.0002 2.77724 1 3.00254V9.9834C0.999974 10.499 1.12084 11.008 1.35382 11.4737C1.5868 11.9394 1.92606 12.35 2.34697 12.6757L6.36804 15.7865C6.54655 15.9246 6.76975 16 7 16C7.23025 16 7.45345 15.9242 7.63196 15.7862Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

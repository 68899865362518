import React from 'react';

export default function SuitcaseIcon() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.3334 16.5V3.16667C12.3334 2.72464 12.1578 2.30072 11.8453 1.98816C11.5327 1.67559 11.1088 1.5 10.6667 1.5H7.33342C6.89139 1.5 6.46746 1.67559 6.1549 1.98816C5.84234 2.30072 5.66675 2.72464 5.66675 3.16667V16.5M2.33341 4.83333H15.6667C16.5872 4.83333 17.3334 5.57953 17.3334 6.5V14.8333C17.3334 15.7538 16.5872 16.5 15.6667 16.5H2.33341C1.41294 16.5 0.666748 15.7538 0.666748 14.8333V6.5C0.666748 5.57953 1.41294 4.83333 2.33341 4.83333Z"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export function ChatIcon () {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.8 8.4H8.4V7H2.8V8.4ZM2.8 6.3H11.2V4.9H2.8V6.3ZM2.8 4.2H11.2V2.8H2.8V4.2ZM7 14L5.1275 11.2H1.4C1.015 11.2 0.685533 11.063 0.4116 10.7891C0.137667 10.5152 0.000466667 10.1855 0 9.8V1.4C0 1.015 0.1372 0.685533 0.4116 0.4116C0.686 0.137667 1.01547 0.000466667 1.4 0H12.6C12.985 0 13.3147 0.1372 13.5891 0.4116C13.8635 0.686 14.0005 1.01547 14 1.4V9.8C14 10.185 13.863 10.5147 13.5891 10.7891C13.3152 11.0635 12.9855 11.2005 12.6 11.2H8.8725L7 14Z"
        fill="currentColor" />
    </svg>
  );
}

export enum ArrowDirection {
  Left='left',
  Right='right',
  Up='up',
  Down='down',
}

interface Props {
  direction?: ArrowDirection;
}

export function ArrowIcon({direction=ArrowDirection.Left}: Props) {
  let rotation = 0;

  switch (direction) {
    case ArrowDirection.Right:
      rotation = 180; // Rotate 180 degrees for right
      break;
    case ArrowDirection.Up:
      rotation = -90; // Rotate -90 degrees for up
      break;
    case ArrowDirection.Down:
      rotation = 90; // Rotate 90 degrees for down
      break;
    case ArrowDirection.Left:
    default:
      rotation = 0; // No rotation for left (default)
      break;
  }

  return (
    <svg
      style={{ transform: `rotate(${rotation}deg)` }}
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.8333 6.99999H1.16667M1.16667 6.99999L7 12.8333M1.16667 6.99999L7 1.16666"
        stroke="currentColor" strokeWidth="1.66667" strokeLinecap="round"
        strokeLinejoin="round" />
    </svg>
  );
}

import React from 'react';

export default function CrossCircleIcon() {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 7C0 5.14348 0.737498 3.36301 2.05025 2.05025C3.36301 0.737498 5.14348 0 7 0C8.85651 0 10.637 0.737498 11.9497 2.05025C13.2625 3.36301 14 5.14348 14 7C14 8.85651 13.2625 10.637 11.9497 11.9497C10.637 13.2625 8.85651 14 7 14C5.14348 14 3.36301 13.2625 2.05025 11.9497C0.737498 10.637 0 8.85651 0 7ZM7 1.00408C5.40978 1.00408 3.8847 1.63579 2.76024 2.76024C1.63579 3.8847 1.00408 5.40978 1.00408 7C1.00408 8.59022 1.63579 10.1153 2.76024 11.2398C3.8847 12.3642 5.40978 12.9959 7 12.9959C8.59022 12.9959 10.1153 12.3642 11.2398 11.2398C12.3642 10.1153 12.9959 8.59022 12.9959 7C12.9959 5.40978 12.3642 3.8847 11.2398 2.76024C10.1153 1.63579 8.59022 1.00408 7 1.00408ZM9.488 4.51306C9.58707 4.61216 9.64272 4.74655 9.64272 4.88668C9.64272 5.02681 9.58707 5.1612 9.488 5.2603L7.74724 7L9.488 8.73969C9.58723 8.83893 9.64297 8.97351 9.64297 9.11385C9.64297 9.25418 9.58723 9.38877 9.488 9.488C9.38877 9.58723 9.25418 9.64297 9.11385 9.64297C8.97351 9.64297 8.83893 9.58723 8.73969 9.488L7 7.74724L5.2603 9.488C5.21117 9.53713 5.15284 9.57611 5.08864 9.6027C5.02445 9.62929 4.95564 9.64297 4.88615 9.64297C4.81667 9.64297 4.74786 9.62929 4.68367 9.6027C4.61947 9.57611 4.56114 9.53713 4.512 9.488C4.46287 9.43886 4.42389 9.38053 4.3973 9.31633C4.37071 9.25214 4.35703 9.18333 4.35703 9.11385C4.35703 9.04436 4.37071 8.97555 4.3973 8.91136C4.42389 8.84716 4.46287 8.78883 4.512 8.73969L6.25276 7L4.512 5.2603C4.41277 5.16107 4.35703 5.02649 4.35703 4.88615C4.35703 4.74582 4.41277 4.61123 4.512 4.512C4.61123 4.41277 4.74582 4.35703 4.88615 4.35703C5.02649 4.35703 5.16107 4.41277 5.2603 4.512L7 6.25276L8.73969 4.512C8.78878 4.46279 8.8471 4.42374 8.9113 4.3971C8.97551 4.37046 9.04433 4.35675 9.11385 4.35675C9.18336 4.35675 9.25218 4.37046 9.31639 4.3971C9.38059 4.42374 9.43891 4.46385 9.488 4.51306Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default function SidebarTogglerIcon () {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0002 4.00001C12.7365 4.00001 13.3335 3.40306 13.3335 2.66668C13.3335 1.9303 12.7365 1.33334 12.0002 1.33334C11.2638 1.33334 10.6668 1.9303 10.6668 2.66668C10.6668 3.40306 11.2638 4.00001 12.0002 4.00001Z"
        stroke="currentColor"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.0002 13.3333C12.7365 13.3333 13.3335 12.7364 13.3335 12C13.3335 11.2636 12.7365 10.6667 12.0002 10.6667C11.2638 10.6667 10.6668 11.2636 10.6668 12C10.6668 12.7364 11.2638 13.3333 12.0002 13.3333Z"
        stroke="currentColor"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.0002 22.6667C12.7365 22.6667 13.3335 22.0697 13.3335 21.3333C13.3335 20.597 12.7365 20 12.0002 20C11.2638 20 10.6668 20.597 10.6668 21.3333C10.6668 22.0697 11.2638 22.6667 12.0002 22.6667Z"
        stroke="currentColor"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.3335 4.00001C22.0699 4.00001 22.6668 3.40306 22.6668 2.66668C22.6668 1.9303 22.0699 1.33334 21.3335 1.33334C20.5971 1.33334 20.0002 1.9303 20.0002 2.66668C20.0002 3.40306 20.5971 4.00001 21.3335 4.00001Z"
        stroke="currentColor"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.3335 13.3333C22.0699 13.3333 22.6668 12.7364 22.6668 12C22.6668 11.2636 22.0699 10.6667 21.3335 10.6667C20.5971 10.6667 20.0002 11.2636 20.0002 12C20.0002 12.7364 20.5971 13.3333 21.3335 13.3333Z"
        stroke="currentColor"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.3335 22.6667C22.0699 22.6667 22.6668 22.0697 22.6668 21.3333C22.6668 20.597 22.0699 20 21.3335 20C20.5971 20 20.0002 20.597 20.0002 21.3333C20.0002 22.0697 20.5971 22.6667 21.3335 22.6667Z"
        stroke="currentColor"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.66683 4.00001C3.40321 4.00001 4.00016 3.40306 4.00016 2.66668C4.00016 1.9303 3.40321 1.33334 2.66683 1.33334C1.93045 1.33334 1.3335 1.9303 1.3335 2.66668C1.3335 3.40306 1.93045 4.00001 2.66683 4.00001Z"
        stroke="currentColor"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.66683 13.3333C3.40321 13.3333 4.00016 12.7364 4.00016 12C4.00016 11.2636 3.40321 10.6667 2.66683 10.6667C1.93045 10.6667 1.3335 11.2636 1.3335 12C1.3335 12.7364 1.93045 13.3333 2.66683 13.3333Z"
        stroke="currentColor"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.66683 22.6667C3.40321 22.6667 4.00016 22.0697 4.00016 21.3333C4.00016 20.597 3.40321 20 2.66683 20C1.93045 20 1.3335 20.597 1.3335 21.3333C1.3335 22.0697 1.93045 22.6667 2.66683 22.6667Z"
        stroke="currentColor"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

import React from 'react';

export default function LockIcon() {
  return (
    <svg
      width="16"
      height="18"
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.83333 8.16667V4.83334C3.83333 3.72827 4.27232 2.66846 5.05372 1.88706C5.83512 1.10566 6.89493 0.666672 8 0.666672C9.10507 0.666672 10.1649 1.10566 10.9463 1.88706C11.7277 2.66846 12.1667 3.72827 12.1667 4.83334V8.16667M2.16667 8.16667H13.8333C14.7538 8.16667 15.5 8.91286 15.5 9.83334V15.6667C15.5 16.5871 14.7538 17.3333 13.8333 17.3333H2.16667C1.24619 17.3333 0.5 16.5871 0.5 15.6667V9.83334C0.5 8.91286 1.24619 8.16667 2.16667 8.16667Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

import {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';

export enum AccountSettingsId {
  AccountDetails = 'account-details',
  SocialLogin = 'social-login',
  Password = 'password',
  TwoFactor = 'two-factor',
  LocationAndLanguage = 'location-and-language',
  Developers = 'developers',
  DeleteAccount = 'delete-account',
  Sessions = 'sessions',
  Payment = 'payment',
}

export default function useSidebar() {
  const [isPaymentsOpen, setIsPaymentsOpen] = useState(false);
  const location = useLocation();
  const isPaymentsRelated = location?.pathname?.includes('/payments') || false

  const togglePayments = () => {
    setIsPaymentsOpen(!isPaymentsOpen);
  };

  const closePaymentsDropdown = () => {
    setIsPaymentsOpen(false)
  }

  useEffect(() => {
    if (isPaymentsRelated && !isPaymentsOpen) {
      setIsPaymentsOpen(true)
    }
  }, []);

  return {
    isPaymentsOpen,
    isPaymentsRelated,
    togglePayments,
    closePaymentsDropdown,
  }
}

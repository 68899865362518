export default function GoogleIcon() {
  return (
    <svg
      width="29"
      height="29"
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.6167 14.8089C26.6167 13.8127 26.5342 13.0858 26.3557 12.332H14.7485V16.8281H21.5617C21.4244 17.9454 20.6826 19.6281 19.0342 20.7588L19.0111 20.9093L22.6811 23.6956L22.9353 23.7204C25.2704 21.6069 26.6167 18.4973 26.6167 14.8089"
        fill="#4285F4"
      />
      <path
        d="M14.7476 26.6552C18.0854 26.6552 20.8876 25.5782 22.9343 23.7205L19.0332 20.7589C17.9893 21.4724 16.5882 21.9704 14.7476 21.9704C11.4784 21.9704 8.70367 19.857 7.71456 16.9359L7.56959 16.9479L3.75352 19.8422L3.70361 19.9781C5.73655 23.9358 9.91237 26.6552 14.7476 26.6552Z"
        fill="#34A853"
      />
      <path
        d="M7.71535 16.9359C7.45437 16.1821 7.30333 15.3743 7.30333 14.5397C7.30333 13.7051 7.45437 12.8974 7.70162 12.1436L7.69471 11.983L3.83081 9.0423L3.70439 9.10123C2.86652 10.7436 2.38574 12.5878 2.38574 14.5397C2.38574 16.4917 2.86652 18.3358 3.70439 19.9782L7.71535 16.9359"
        fill="#FBBC05"
      />
      <path
        d="M14.7476 7.10899C17.069 7.10899 18.6349 8.09168 19.5278 8.91289L23.0168 5.57441C20.874 3.62249 18.0855 2.42441 14.7476 2.42441C9.9124 2.42441 5.73656 5.14362 3.70361 9.10128L7.70086 12.1436C8.7037 9.22248 11.4784 7.10899 14.7476 7.10899"
        fill="#EB4335"
      />
    </svg>
  )
}

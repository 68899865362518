import {List, ListItem} from '@common/ui/list/list';
import {PersonIcon} from '@common/icons/material/Person';
import {Trans} from '@common/i18n/trans';
import {LoginIcon} from '@common/icons/material/Login';
import {LockIcon} from '@common/icons/material/Lock';
import {PhonelinkLockIcon} from '@common/icons/material/PhonelinkLock';
import {LanguageIcon} from '@common/icons/material/Language';
import {ApiIcon} from '@common/icons/material/Api';
import {DangerousIcon} from '@common/icons/material/Dangerous';
import {ReactNode, useContext} from 'react';
import {DevicesIcon} from '@common/icons/material/Devices';
import {useAuth} from '@common/auth/use-auth';
import {useSettings} from '@common/core/settings/use-settings';
import {SiteConfigContext} from '@common/core/settings/site-config-context';
import {SitenavLink} from './sitenav-link';

interface AccountSettingsSidenavProps {
  link?: boolean;
}
export enum AccountSettingsId {
  AccountDetails = 'account-details',
  SocialLogin = 'social-login',
  Password = 'password',
  TwoFactor = 'two-factor',
  LocationAndLanguage = 'location-and-language',
  Developers = 'developers',
  DeleteAccount = 'delete-account',
  Sessions = 'sessions',
  Payment = 'payment',
}

export function AccountSettingsSidenav({ link = false }: AccountSettingsSidenavProps) {

  const p = AccountSettingsId;
  const {hasPermission} = useAuth();
  const {api, social} = useSettings();
  const {auth} = useContext(SiteConfigContext);

  const socialEnabled =
    social?.envato || social?.google || social?.facebook || social?.twitter;

  return (
    <aside className="sticky top-10 hidden flex-shrink-0 lg:block">
      <List padding="p-0">
        {auth.accountSettingsPanels?.map(panel => (
          <Item
            key={panel.id}
            icon={<panel.icon viewBox="0 0 50 50" />}
            panel={panel.id as AccountSettingsId}
          >
            <Trans {...panel.label} />
          </Item>
        ))}
        <SitenavLink
          to={link? `/account-settings` : ''}
          message='Account details'
          id={p.AccountDetails}
          icon={<PersonIcon />}
        />
        {socialEnabled && (
          <SitenavLink
            to={link? `/account-settings` : ''}
            message='Social login'
            id={p.SocialLogin}
            icon={<LoginIcon />}
          />
        )}
        <SitenavLink
          to={link? `/account-settings` : ''}
          message='Password'
          id={p.Password}
          icon={<LockIcon />}
        />
        <SitenavLink
          to={link? `/account-settings` : ''}
          message='Two factor authentication'
          id={p.TwoFactor}
          icon={<PhonelinkLockIcon />}
        />
        <SitenavLink
          to={link? `/account-settings` : ''}
          message='Active sessions'
          id={p.Sessions}
          icon={<DevicesIcon />}
        />
        <SitenavLink
          to={link? `/account-settings` : ''}
          message='Location and language'
          id={p.LocationAndLanguage}
          icon={<LanguageIcon />}
        />
        {api?.integrated && hasPermission('api.access') ? (
          <SitenavLink
            to={link? `/account-settings` : ''}
            message='Developers'
            id={p.Developers}
            icon={<ApiIcon />}
          />
        ) : null}
        <SitenavLink
          to={link? `/account-settings` : ''}
          message='Delete account'
          id={p.DeleteAccount}
          icon={<DangerousIcon />}
        />
      </List>
    </aside>
  );
}

interface ItemProps {
  children: ReactNode;
  icon: ReactNode;
  isLast?: boolean;
  panel: AccountSettingsId;
}
export function Item({children, icon, isLast, panel}: ItemProps) {
  return (
    <ListItem
      startIcon={icon}
      className={isLast ? undefined : 'mb-10'}
      onSelected={() => {
        const panelEl = document.querySelector(`#${panel}`);
        if (panelEl) {
          panelEl.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
        }
      }}
    >
      {children}
    </ListItem>
  );
}
